
import { Options, Vue } from 'vue-class-component';
import { DatePicker } from 'ant-design-vue';
import SupplierVersion from '@/components/supplier-version.vue';
import {
  FormworkListService,
  GetModelParam,
  ModelSimple,
} from './formwork-list.service';
@Options({
  components: {
    SupplierVersion,
  },
})
export default class FormworkList extends Vue {
  public industry = '';
  public deviceCode = '';
  public dateRange = [];
  public value1 = new Date();
  public height = 300;
  public columns = [
    {
      title: '设备商',
      width: 150,
      dataIndex: 'supplier',
      key: '1',
    },
    {
      title: '型号',
      width: 150,
      dataIndex: 'type',
      key: '2',
    },
    {
      title: '软件版本号',
      dataIndex: 'version',
      key: '3',
      width: 150,
    },
    { title: '最后更新时间', dataIndex: 'update_time', key: '4', width: 200 },
    { title: '修改人', dataIndex: 'operator', key: '5', width: 150 },
    {
      title: '操作',
      key: '6',
      dataIndex: 'id',
      width: 200,
      slots: { customRender: 'action' },
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public list: ModelSimple[] = [];
  public supplier: string = '';
  public type: string = '';
  public version: string = '';
  public pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };
  public param: GetModelParam = new GetModelParam();
  public async mounted() {
    // const target = new ModelSimple();
    // target.id = 1;
    // target.supplier = 'aa';
    // target.type = '1.2';
    // this.list = [target];
    this.$storage.get<number>('height-formwork-list').then((value) => {
      this.height = value ? value : 300;
    });
    window.onresize = this.checkHeight;
    this.search();
  }
  public checkHeight() {
    const table = document.querySelector('#table');
    this.height = table ? table.clientHeight - 64 - 54 - 20 - 45 : 300;
    this.$storage.save('height-formwork-list', this.height);
  }
  public async fresh(page: {
    current: number;
    pageSize: number;
    total: number;
  }) {
    this.pagination = page;
    this.param.page_number = page.current;
    this.param.page_size = page.pageSize;
    this.$base.loading.show();
    // tslint:disable-next-line:max-line-length
    const http = await this.$http
      .get<{ list: ModelSimple[]; total: number }>(
        `${process.env.VUE_APP_API}/devices/models`,
        this.param,
      )
      .then(
        (r) => {
          this.list = r.list;
          this.pagination.total = r.total;
          this.checkHeight();
        },
        (e) => {
          this.$base.error(e);
        },
      );
    this.checkHeight();
    this.$base.loading.close();
  }
  public search() {
    this.param.device_supplier = this.supplier;
    this.param.device_type = this.type;
    this.param.device_version = this.version;
    this.pagination.current = 1;
    return this.fresh(this.pagination);
  }
  public deleteFormwork(val: ModelSimple) {
    const active = () => {
      this.$base.loading.show();
      const a = this.$http.put(`${process.env.VUE_APP_API}/devices/models/${val.id}`, {
        model: '',
        supplier: val.supplier,
        type: val.type,
        version: val.version,
      }).then( (r) => {
        this.$base.success('删除成功');
      }).catch( (e) => {
        this.$base.error(e);
      });
      this.$base.loading.close();
    };
    this.$base.info('如果删除模板, 将会清空模板的内容，确认要这么做吗？', active, '警告');
  }
  public onChange(date: Date, dateString: string) {
    console.log(date, dateString);
  }
}
