import { AxiosPromise } from 'axios';
// tslint:disable:variable-name
import Http from '@/core/http.service';
import { Presupposition } from '../presupposition-list/presupposition.model';
export class GetModelParam {
    public device_supplier: string | undefined;
    public device_type: string | undefined;
    public device_version: string | undefined;
    public page_number: number | undefined;
    public page_size: number | undefined;
}
export class ModelSimple {
    public id?: number;
    public supplier: string | undefined;
    public type: string | undefined;
    public version: string | undefined;
    public update_time?: string | undefined;
    public operator?: string | undefined;
    public list: Presupposition[] = [];
}
export class FormworkListService {
    public baseUrl: string = process.env.VUE_APP_API;
    // tslint:disable-next-line:member-access
    $http = Http;
    public getModelList(param: GetModelParam): AxiosPromise<{list: ModelSimple[], total: number}> {
        return this.$http.get(`${process.env.VUE_APP_API}/devices/models`, param);
    }
}
