<template>
  <div class="flex flex-wrap">
    <div class="search-item">
      设备商：
      <a-select
        v-model:value="supplier"
        style="width: 160px"
        ref="select"
        allowClear
        :placeholder="defaultSupplier"
        @change="getTypes"
        :disabled="disabled"
      >
        <a-select-option
          v-for="(value, index) in suppliers"
          :key="index"
          :value="value.supplier"
        >
          {{ value.supplier }}
        </a-select-option>
      </a-select>
    </div>
    <div class="search-item">
      型号：
      <a-select
        v-model:value="type"
        style="width: 160px"
        ref="select"
        allowClear
        :placeholder="defaultType"
        @change="getVersions"
        :disabled="disabled"
      >
        <a-select-option
          v-for="(value, index) in types"
          :key="index"
          :value="value.type"
        >
          {{ value.type }}
        </a-select-option>
      </a-select>
    </div>
    <div class="search-item">
      版本软件号：
      <a-select
        v-model:value="version"
        style="width: 160px"
        ref="select"
        allowClear
        :placeholder="defaultVersion"
        @change="changeVersion"
        :disabled="disabled"
      >
        <a-select-option
          v-for="(value, index) in versions"
          :key="index"
          :value="value.version"
        >
          {{ value.version }}
        </a-select-option>
      </a-select>
    </div>
    <div class="search-item" v-if="showName">
      预设名称：
      <a-input v-model:value="paramName" style="width: 160px" @change="$emit('update:paramName', $event)" />
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
@Options({
  components: {},
  props: {
    // supplier: String,
    // type: String,
    // version: String,
    disabled: Boolean,
    paramName: String,
    showDefault: Boolean,
    showName: Boolean,
    defaultSupplier: String,
    defaultType: String,
    defaultVersion: String,
  },
  emits: {
    'update:supplier': String,
    'update:type': String,
    'update:version': String,
    'update:paramName': String,
  },
})
export default class SupplierVersion extends Vue {
  public suppliers: Array<{ supplier: string }> = [];
  public types: Array<{ type: string }> = [];
  public versions: Array<{ version: string }> = [];
  // tslint:disable-next-line:variable-name
  private _showDefault = false;
  private disabled!: boolean;
  set showDefault(value) {
    this._showDefault = value;
  }
  get showDefault(): boolean {
    return this._showDefault;
  }
  private defaultSupplier: string = '请选择设备商';
  private defaultType: string = '请选择型号';
  private defaultVersion: string = '请选择版本';
  private supplier!: string;
  private type!: string | undefined;
  private version!: string | undefined;
  public mounted() {
    this.getSuppliers();
  }
  public async getSuppliers() {
    this.$base.loading.show();
    return this.$base.getSuppliers().then(
      (r) => {
        this.suppliers = r;
        this.versions = [];
        this.types = [];
        if (!this._showDefault) {
          this.version = undefined;
          this.type = undefined;
        } else {
          this._showDefault = false;
        }
        this.changeType();
        this.changeSupplier();
        this.changeVersion();
        this.$base.loading.close();
      },
      (e) => {
        this.$base.loading.close();
        this.$base.error(e, '获取供应商列表失败');
      },
    );
  }
  public async getTypes() {
    this.changeSupplier();
    if (this.supplier) {
      this.type = undefined;
      this.changeType();
      this.$base.loading.show();
      return this.$base.getTypes(this.supplier).then(
        (r) => {
          console.log('types', r);
          this.types = r;
          this.version = undefined;
          this.changeVersion();
          this.versions = [];
          this.$base.loading.close();
        },
        (e) => {
          this.$base.loading.close();
          this.$base.error(e, '获取设备列表失败');
        },
      );
    } else {
      this.version = undefined;
      this.versions = [];
      this.type = undefined;
      this.types = [];
      this.changeVersion();
      this.changeType();
    }
  }

  public async getVersions() {
    this.changeType();
    if (this.type) {
      this.version = undefined;
      this.changeVersion();
      this.$base.loading.show();
      return this.$base.getVersions(this.supplier, this.type).then(
        (r) => {
          console.log('versions', r);
          this.versions = r;
          this.$base.loading.close();
        },
        (e) => {
          this.$base.loading.close();
          this.$base.error(e, '获取版本列表失败');
        },
      );
    } else {
      this.version = undefined;
      this.changeVersion();
      this.versions = [];
    }
  }

  public changeVersion() {
    this.$emit('update:version', this.version);
  }

  public changeType() {
    this.$emit('update:type', this.type);
  }

  public changeSupplier() {
    this.$emit('update:supplier', this.supplier);
  }
}
</script>
<style lang="less">
</style>